.imageStyle {
    width:100%;
    height:100%;
    object-fit:contain;
}
.centerStyle {
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
}
.clickable:active {
    opacity: .9;
}
.clickOverlay {
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    cursor:pointer;
}
