.root {
    width: 100%;
    overflow: auto;
    margin-bottom: 2rem;
    display: flex
}
.horizontal-flexbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto
}
.headlineText {
    font-family: Lato;
    font-weight: 400;
    font-size: 16px;
    color: #656565;
    letter-spacing: -0.19px;
    flex-shrink: 0;
    text-align: center;
    height: 40px;
    width: 116px;
    line-height: 16px;
    padding-left: 1rem;
    padding-right: 1rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: rgba(238, 238, 238, 1);
    border-radius: 20px;
    margin-right: 5px;
    margin-bottom: 8px;
}
.headlineText:active {
    opacity: 1;
}
.headlineText:hover {
    opacity: .75;
}
.sliderContaine {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
}
.slider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    align-items: center;
    flex-wrap: wrap;
    line-height: 1.7rem
}
.pipe {
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center
}
