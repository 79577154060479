.login-button {
  padding: 10px;
}

.sign-in-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: calc(1rem + 12px);
  font-size: 17px;

  @media (max-width: 480px) {
    font-size: 17px;
  }
}
