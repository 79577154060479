@import "../../App.scss";

.station-detailed-container {
  flex-direction: row;
  display: flex;
  margin-left: 21px;
  margin-top: 19px;

  &.mobile {
    flex-direction: column;
    display: flex;
  }

  @media (max-width: 960px) {
    margin-left: 60px;
    margin-top: 58px;
  }

  @media (max-width: 580px) {
    margin-left: 30px;
  }

  @media (max-width: 530px) {
    margin-left: 10px;
  }

  @media (max-width: 480px) {
    margin-left: 27px;
    margin-right: 26px;
    margin-top: 26px;
  }

  .station-detailed-logo {
    flex-direction: column;
    display: flex;

    @media (max-width: 510px) {
      justify-content: center;
    }

    .station-detailed-logo-img {
      width: 393px;
      height: 393px;

      @media (max-width: 960px) {
        width: 266px;
        height: 266px;
      }

      @media (max-width: 510px) {
        width: 210px;
        height: 210px;
      }

      @media (max-width: 480px) {
        width: 100%;
        // height: 361px;
        height: auto;
      }
    }
  }

  .station-detailed-infos {
    flex-direction: column;
    display: flex;
    margin-left: 33px;
    justify-content: center;

    &.mobile {
      margin-top: 26px;
    }

    @media (max-width: 960px) {
      margin-left: 20px;
    }

    @media (max-width: 480px) {
      margin-left: 0px;
    }

    .genre {
      font-family: Lato;
      font-weight: bold;
      font-size: 20px;
      line-height: 33px;
      letter-spacing: 0.4px;
      display: flex;
      color: $color-detailed-grey;
    }

    .name-heart-container {
      flex-direction: row;
      display: flex;

      .name {
        font-family: Lato;
        font-weight: bold;
        font-size: 54px;
        line-height: 65px;
        color: $color-detailed-headline;
        display: flex;
        text-align: left;

        @media (max-width: 960px) {
          font-size: 32px;
          line-height: 38px;
        }
      }
    }

    .description {
      font-family: Lato;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.4px;
      display: flex;
      color: $color-detailed-grey;
      margin-bottom: 6px;
      text-align: left;

      @media (max-width: 480px) {
        margin-top: 7px;
        margin-bottom: 0px;
      }
    }

    .station-detailed-album {
      flex-direction: row;
      display: flex;
      margin-top: 11px;

      @media (max-width: 480px) {
        margin-top: 26px;
      }

      .album-img {
        flex-direction: column;
        margin-right: 14px;

        @media (max-width: 480px) {
          margin-right: 25px;
        }
      }

      .album-infos {
        flex-direction: column;
        display: flex;
        align-items: flex-start;

        @media (max-width: 480px) {
          justify-content: center;
        }

        .album-title {
          font-family: Lato;
          font-weight: bold;
          font-size: 20px;
          line-height: 33px;
          letter-spacing: 0.4px;
          color: $color-detailed-headline;
          text-align: left;

          @media (max-width: 480px) {
            font-size: 14px;
            line-height: 24px;
          }
        }

        .album-artist-name {
          font-family: Lato;
          font-weight: normal;
          font-size: 20px;
          line-height: 33px;
          letter-spacing: 0.4px;
          color: $color-detailed-grey;
          text-align: left;

          @media (max-width: 480px) {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

.similiar-stations-grid {
  margin-top: 49px !important;

  @media (max-width: 960px) {
    margin-top: 104px !important;
  }
}
