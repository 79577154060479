.root {
    display:flex;
    flex-direction:row;
    align-items:center;
}
.chevronContainer {
    display:flex;
    align-items:center;
    justify-content:center;
    flex-shrink:0;
    width:32px;
    height:48px;
    cursor:pointer;
}
.chevronContainer:active {
    opacity: 1;
}
.chevronContainer:hover {
    opacity: .75;
}
.chevron {
    width:24px;
    height:24px;
    object-fit:contain;
}
.gridListContainer {
    flex:1;
    display:flex;
    flex-wrap:wrap;
    justify-content:space-around;
    overflow:hidden;
    scroll-behavior:smooth;
}
.gridList {
    flex-wrap:nowrap;
    transform:translateZ(0);
}
.scrollX {
    overflow-x: auto;
}
.titleBar {
    background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)
}
