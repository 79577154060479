$color-app-drawer-background: #222223;
$color-primary-black: #222223;
$color-light-grey: #a7a7ab;
$color-darker-grey: #656565;
$color-white: #ffffff;
$color-green: #1de9b6;
$color-light-grey-title: #cfcfcf;
$color-pink: #ff3f90;
$color-purple: #200045;
$color-headline: #4c4c4c;
$color-dark-grey: #606060;
$color-black: #000000;
$color-seperator: #979797;
$color-detailed-grey: #a1a4ab;
$color-detailed-headline: #4c4c4c;

$text-size-huge: 1.75rem; //2.0vmax;		//28px
$text-size-headline: 1.5625rem; //1.8vmax;		//25px
$text-size-headline2: 1.375rem; //1.75vmax;		//22px
$text-size-medium: 1.125rem; //1.15vmax;		//17-18px
$text-size-small: 1rem;
$text-size-regular: 0.875rem; //1.1vmax;		//14-15px
$text-size-station: 0.8125rem; //13px;			//13px
$text-size-tiny: 0.6875rem; //0.9vmax;			//11px

$dimen-drawer-width-open: 222px;
$dimen-drawer-width-closed: 72px;
$dimen-drawer-width-open-mobile: 200px;
$dimen-drawer-width-closed-mobile: 0px;

:root {
  --app-drawer-background-color: #222223;
  --primary-black: #222223;
  --light-grey: #a7a7ab;
  --darker-grey: #656565;
  --white: #ffffff;

  --dimen-drawer-width-open: #{$dimen-drawer-width-open};
  --dimen-drawer-width-closed: #{$dimen-drawer-width-closed};
  --dimen-drawer-width-open-mobile: #{$dimen-drawer-width-open-mobile};
  --dimen-drawer-width-closed-mobile: #{$dimen-drawer-width-closed-mobile};
}

$html-font-size-lg: 16px;
$html-font-size-md: 15px;
$html-font-size-sm: 13px;
$html-font-size-xs: 11px;
html {
  font-size: $html-font-size-lg;
  line-height: 23px;

  @media (max-width: 1024px) {
    font-size: $html-font-size-md;
  }

  @media (max-width: 640px) {
    font-size: $html-font-size-sm;
  }

  @media (max-width: 480px) {
    font-size: $html-font-size-xs;
  }
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.App-header {
  /* background-color: #282c34; */
  background-color: $color-white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $color-primary-black;
}
.App-page {
  /* background-color: #282c34; */
  background-color: $color-white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: $color-primary-black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-Drawer-Top-Bar {
  background-color: $color-purple;
  height: 55px;
  width: calc(100% - 222px);
  align-self: flex-end;
}

.App-Drawer-Top-Bar-Small {
  background-color: $color-purple;
  height: 59px;
  width: 100%;
  align-self: flex-end;
}

.App-Drawer-Navigation-Item {
  font-family: "Lato";
  font-weight: 400; /* Black */
  font-size: $text-size-medium;
  color: $color-white;
  background-color: rgba(242, 242, 242, 0.2);
  letter-spacing: -0.36px;
  white-space: nowrap; // fixes word wrap
  border-top-left-radius: 18.5px 50%;
  border-bottom-left-radius: 18.5px 50%;
  border-top-right-radius: 18.5px 50%;
  border-bottom-right-radius: 18.5px 50%;
  display: flex;
  align-content: center;
  // height: 2.6rem;
  height: 40px;
  margin-bottom: 0.6rem;
  width: 100%;

  max-width: 158px;

  @media (max-width: 480px) {
    max-width: 168px;
  }
}

.App-Drawer-Genres {
  font-family: "Lato";
  font-weight: bold; /* Black */
  letter-spacing: 0.4px;
  color: $color-white;
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap;
  margin-bottom: 0.3rem;
  margin-left: calc(1rem + 12px);
  cursor: pointer;
  flex-direction: row;
  display: flex;

  &:hover {
    opacity: 0.75;
  }
}

.App-Drawer-Navigation-Item-SignIn {
  font-family: "Lato";
  font-weight: 400; /* Black */
  font-size: $text-size-small;
  color: $color-white;
  background-color: $color-pink;
  letter-spacing: -0.36px;
  white-space: nowrap; // fixes word wrap
  border-top-left-radius: 18.5px 50%;
  border-bottom-left-radius: 18.5px 50%;
  border-top-right-radius: 18.5px 50%;
  border-bottom-right-radius: 18.5px 50%;
  display: flex;
  align-content: center;
  // height: 2.6rem;
  height: 40px;
  margin-bottom: 0.6rem;
  width: 100%;

  max-width: 158px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: 480px) {
    max-width: 168px;
  }
}

.App-Drawer-Navigation-Item-Disabled {
  font-family: "Lato";
  font-weight: 400; /* Bold */
  font-size: $text-size-medium;
  color: $color-white;
  letter-spacing: -0.36px;
  white-space: nowrap; // fixes word wrap
}

.App-Drawer-shift-style {
  width: 100%;
  min-height: 100vh;
  transition: all 250ms;
}

.App-Drawer-Navigation-Item-Secondary {
  font-size: 16px;
  line-height: 24px;
  align-self: center;
  margin-left: 1rem;
  flex-direction: row;
  display: flex;

  @media (max-width: 480px) {
    fontsize: 16px;
  }

  &:hover {
    opacity: 0.75;
  }
  &:active {
    opacity: 1;
  }
}

.App-Drawer-Navigation-Item-Secondary-active {
  font-size: 16px;
  line-height: 24px;
  align-self: center;
  margin-left: 1rem;
  flex-direction: row;
  display: flex;

  @media (max-width: 480px) {
    fontsize: 16px;
  }

  &:hover {
    opacity: 0.75;
  }
  &:active {
    opacity: 1;
  }
}

.App-Drawer-Navigation-Item-Tiny {
  /*font-family: 'Lato';
  font-weight: 700;*/ /* Bold */
  /* font-size: 11px; */
  font-size: $text-size-tiny;
  letter-spacing: -0.14px;
  //line-height: 13px;
  line-height: calc(2px + #{$text-size-tiny});
}

.App-Player-Button-Text {
  font-family: "Lato";
  font-weight: 400; /* Regular */
  font-size: 14px;
  // font-size: $text-size-tiny;
  // color: $color-light-grey;
  color: rgba(255, 255, 255, 0.541111);
  letter-spacing: -0.14px;
}
.App-Player-Button-Text-Active {
  font-family: "Lato";
  font-weight: 400; /* Regular */
  font-size: 14px;
  // font-size: $text-size-tiny;
  color: $color-white;
  letter-spacing: -0.14px;
}
.App-Player-Station-Text {
  font-family: "Lato";
  font-weight: 400; /* Regular */
  /* font-size: 14px; */
  font-size: $text-size-regular;
  color: $color-light-grey;
  letter-spacing: -0.18px;
  width: 100%;
  text-align: right;
  line-height: calc(2px + #{$text-size-regular});
  max-height: calc(2 * (2px + #{$text-size-regular}));
  overflow: hidden;
}
.App-Player-Song-Title-Text {
  font-family: "Lato";
  font-weight: 900; /* Regular */
  font-size: 22px;
  color: $color-white;
  letter-spacing: 0.628572px;
  line-height: 24px;
  overflow: hidden;

  @media (max-width: 960px) {
    font-size: 15px;
    letter-spacing: 0.428571px;
  }

  @media (max-width: 480px) {
    font-size: 10px;
    letter-spacing: 0.285714px;
  }
}
.App-Player-Song-Artist-Text {
  font-family: "Lato";
  font-weight: normal; /* Regular */
  font-size: 15px;
  color: $color-white;
  letter-spacing: -0.17px;
  line-height: 17px;
  overflow: hidden;

  @media (max-width: 480px) {
    font-size: 10px;
    letter-spacing: 0.285714px;
  }
}

.App-Player-Volume-Bar {
  color: #565657;
  background-color: #cfcfcf;
}

.App-Station-Item-Title {
  font-family: "Lato";
  font-weight: 400; /* Regular */
  font-size: 18px;
  color: $color-black;
  letter-spacing: -0.2875px;
  margin-bottom: 4px;

  width: calc(261px - 28px);
  line-height: 17px;
  max-height: 34px; // line-height * line count

  display: block;
  display: -webkit-box;
  max-width: 100%;
  // margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.App-Station-Item-Subtitle {
  font-family: "Lato";
  font-weight: 300; /* Light */
  font-size: 12px;
  color: $color-headline;
  letter-spacing: -0.12px;
  width: calc(261px - 28px);

  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 36px;
  margin: 0 auto;
  line-height: 12px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.social-button {
  flex: 1;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-left: 10px;
}

.dialog-popups {
  left: 256px;
  top: 119px;
  position: fixed;
  width: 338px;
  height: 600px;
  background-color: #fff;
  color: #000;
  transition: all 250ms;
  overflow: auto;
  background: linear-gradient(0deg, #200044 0%, #ff3f90 100%);
  border-radius: 8px;

  @media (max-width: 960px) {
    left: 0;
    right: 0;
    top: 10%;
    height: 90%;
    max-height: 550px;
    margin: auto;
  }

  &.logged-in {
    right: 100;
    top: 100;
    position: fixed;
    width: 325;
    height: 180px;

    @media (max-width: 960px) {
      right: 5vw;
      top: 5vh;
      width: 90vw;
    }
  }

  .dialog-title {
    font-family: "Lato";
    font-size: 16px;
    line-height: 24px;
    color: white;

    &.go-back {
      margin-bottom: 41px;
      margin-top: 7px;
      cursor: pointer;
    }
  }
}

.simple-input-container {
  margin-bottom: 12px;

  &.popup {
    margin-bottom: 11px;
  }
}

.popup-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 275px;
  min-width: 275px;

  &:hover {
    opacity: 0.75;
  }
}

.social-buttons-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popup-button {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff3f90;
  padding: 2px 16px;
  transition: all 200ms;
  border-radius: 30px;
  cursor: pointer;
  font-family: "Lato";
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  line-height: 24px;

  &.email {
    justify-content: flex-start;
    padding-left: 33px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.popup-header-container {
  display: flex;
  align-items: center;
  padding-left: 33px;
  padding-right: 30px;
  padding-top: 29px;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
}

.popup-title {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.popup-text-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.popup-app-text {
  color: white;
  font-size: 16px;
  line-height: 24px;
  font-family: "Lato";
  font-weight: normal;
}

.popup-simple-input {
  flex: 1;
  flex-shrink: 0;
  max-width: 275px;
  min-width: 275px;
  background: rgba(242, 242, 242, 0.2);
  padding: 8px 16px;
  border-radius: 20px;
  height: 40px;
}

.popup-simple-input-text {
  text-align: center;
  font-family: "Lato" !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #fff !important;
  height: unset !important;
  padding: unset !important;
}

.App-Horizontal-Separator {
  width: 100%;
  height: 1.5px;
  background: $color-light-grey;
  margin-bottom: calc(0.25 * #{$text-size-regular});
  flex-shrink: 0;
}
.App-Page-Headline-Text {
  font-family: "Lato";
  font-weight: bold;
  /* font-size: 25px; */
  font-size: 32px;
  color: $color-black;
  letter-spacing: 0;
  width: 100%;
  text-align: start;
  padding-bottom: calc(0.5 * #{$text-size-regular});
  flex-shrink: 0;

  @media (max-width: 960px) {
    font-size: 28px;
  }

  &.guide {
    width: calc(100% - 222px - 14%);
    // height: 51px;
    height: unset !important;

    @media (max-width: 960px) {
      font-size: 28px;
    }

    @media (max-width: 480px) {
      width: 100%;
    }
  }
}

.App-Page-Headline-Sub-Text {
  font-family: "Lato";
  font-weight: 700; /* Bold */
  /* font-size: 17px; */
  font-size: $text-size-medium;
  color: $color-light-grey;
  letter-spacing: -0.22px;
  width: 100%;
  text-align: start;
  padding-bottom: calc(0.5 * #{$text-size-regular});
  flex-shrink: 0;
}
.App-Page-Headline-Sub-Text-Title {
  font-family: "Lato";
  font-weight: 700; /* Bold */
  /* font-size: 17px; */
  font-size: $text-size-medium;
  color: $color-light-grey-title;
  letter-spacing: -0.22px;
  width: 100%;
  text-align: start;
  padding-bottom: calc(0.5 * #{$text-size-regular});
  flex-shrink: 0;
}

.App-Page-Headline-Sub-Text-DashStudio {
  font-family: "Lato";
  font-weight: 700; /* Bold */
  /* font-size: 17px; */
  font-size: $text-size-medium;
  color: $color-light-grey;
  letter-spacing: -0.22px;
  // line-height: 1px;
  width: 100%;
  text-align: start;
  padding-bottom: calc(0.5 * #{$text-size-regular});
  flex-shrink: 0;
}
.App-Page-Headline2-Text {
  font-family: "Lato";
  font-weight: 700; /* Bold */
  /* font-size: 22px; */
  font-size: $text-size-headline2;
  color: $color-primary-black;
  letter-spacing: -0.28px;
  text-align: start;
  padding-bottom: calc(0.5 * #{$text-size-regular});
  flex-shrink: 0;
}
.App-Page-Headline-Big-Text {
  font-family: "Lato";
  font-weight: 700; /* Bold */
  /* font-size: 28px; */
  font-size: $text-size-huge;
  color: $color-primary-black;
  letter-spacing: -0.4px;
  text-align: start;
  padding-bottom: calc(0.5 * #{$text-size-regular});
  flex-shrink: 0;
  line-height: 1.8rem;
}
.App-Page-Text {
  font-family: "Lato";
  font-weight: 400; /* Regular */
  /* font-size: 15px; */
  font-size: $text-size-regular;
  color: $color-dark-grey;
  letter-spacing: -0.22px;
  text-align: start;
  // padding-bottom: calc(0.5 * #{$text-size-regular});
  flex-shrink: 0;
}

.App-Page-Text-Bold {
  font-family: "Lato";
  font-weight: 700; /* Bold */
  /* font-size: 15px; */
  font-size: $text-size-regular;
  color: $color-primary-black;
  letter-spacing: -0.22px;
  text-align: start;
  padding-bottom: calc(0.5 * #{$text-size-regular});
  flex-shrink: 0;
}
.App-Page-Text-Black {
  font-family: "Lato";
  font-weight: 900; /* Black */
  /* font-size: 17px; */
  font-size: $text-size-medium;
  color: $color-primary-black;
  letter-spacing: -0.22px;
  text-align: start;
  flex-shrink: 0;
  margin-top: 0.8rem;
}

.App-SideBar-Navigation-Item {
  border: 1px solid #f2f2f2;
  width: 3rem;
  margin: 0;
  opacity: 0.2;
}
.App-SideBar-Navigation-Item-Disabled {
  border: 1px solid #1de9b6;
  width: 3rem;
  margin: 0;
}

// .false{
//   margin-top: .8rem;
// }

.App-Page-Text-Grey {
  font-family: "Lato";
  font-weight: 900; /* Black */
  /* font-size: 17px; */
  font-size: $text-size-medium;
  color: $color-light-grey;
  letter-spacing: -0.22px;
  text-align: start;
  flex-shrink: 0;
}

.App-Station-Seperator {
  width: calc(100% - 28px);
  height: 4px;
  border-radius: 11px;
  position: absolute;
  bottom: 10px;
}

.station-items {
  width: 261px;
  height: 261px;
  display: flex;
  align-items: center;
  border-radius: 14px;
  margin: 16px !important;
  // padding: 6px;
  padding: 0px !important;
}

.header-backdrop-container {
  position: absolute;
  right: 1rem;
  top: 1rem;
  color: black;
  z-index: 1400;
  cursor: pointer;
}

.drawer-item-button {
  padding: 10px;
}

.drawer-item-smallDrawerText {
  margin-top: -8px;
  color: #656565;
  font-weight: 400;
  &.sel {
    color: #222223;
    font-weight: 700;
  }
}

.Font-Regular {
  font-family: "Lato";
  font-weight: 400; /* Regular */
}
._2Nde-b7zBqxE2c-pX-RLQr {
  display: flex;
}
.CB9vby5YRbJWpv7hRrZ0x {
  margin-bottom: 22px;
  margin-left: 10px;
  margin-right: 10px;
  // height: 7rem;
}
._2VaQi-Tq1LrSSO0_knReFa {
  opacity: 1 !important;
  // max-width: 336px;
  max-width: calc(100%);
}
._22fNtVvEKNZkRmg_lseZsh {
  display: flex !important;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.ticker__element {
  height: 100% !important;
}
.ticker {
  // height: 100% !important;
}

ul {
  display: flex !important;
  margin-left: auto !important;
  justify-content: flex-start;
}

.highlighting-words {
  color: black;
  font-family: "Lato";
  font-weight: bold;
  letter-spacing: 0.4px;
  font-size: 14px;
}

.video-section {
  width: calc(100% - 222px);
  margin-left: 222px;
  position: relative;
  z-index: 1;

  @media (max-width: 960px) {
    margin-left: unset;
    width: 100%;
  }

  @media (max-width: 480px) {
    width: 100vw;
    margin-left: unset;
  }
}

:-webkit-direct-focus {
  outline: none !important;
  outline-style: none !important;
}

// .marquee-image {
//   white-space: nowrap;
//   overflow: hidden;
//   padding-left: 100%;
// }

// .marquee-image img {
//   display: inline-block;
//   padding-left: 100%;
//   animation: marquee 20s linear infinite;
// }

// @keyframes marquee {
//   0% {
//     transform: translateX(0);
//   }
//   100% {
//     transform: translateX(-4000%);
//   }
// }

.listen-everywhere-banner-container {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 362px;

  @media (max-width: 960px) {
    min-height: 273px;
  }

  .listen-everywhere-banner-image {
    max-height: 78px;

    @media (max-width: 1069px) {
      max-height: 63px;
    }

    @media (max-width: 679px) {
      max-height: 37px;
    }
  }
}
.rec {
  .rec-dot {
    width: 8px;
    height: 8px;
    box-shadow: none;
    background-color: #D8D8D8;
  }
  .rec-dot_active {
    background-color: #515151;
  }
  .rec-arrow {
    color: black;
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
  }
  .rec-arrow:hover {
    background-color: transparent;
    color: #D8D8D8;
    box-shadow: none;
  }
  .rec-arrow:disabled {
    visibility: hidden;
    cursor: pointer;
  }
}
