.background {
    height:40px;
    width:20%;
    display:flex;
    justify-content:center;
    align-items:center;
    background:#FF3F90;
    padding:2px 16px;
    transition:all 200ms;
    border-radius:30px;
}
.clickable {
    cursor: pointer;
}
.clickable:active {
    opacity: .8;
}
.inactive {
    opacity: .5;
}
.text {
    font-family:Lato;
    font-weight:700;
    font-size:1rem;
    color:#FFFFFF;
    letter-spacing: -0.23pt;
}
