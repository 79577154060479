@import "../../../../App.scss";

.topbar-container {
  background-color: $color-purple;
  height: 55px;
  width: calc(100% - 222px);
  align-self: flex-end;

  @media (max-width: 960px) {
    height: 59px;
    width: 100%;
  }

  .topbar-inner-container {
    height: 100%;
    align-items: center;
    display: flex;
    padding-left: 26px;
    padding-right: 10px;

    .dash-logo {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      // padding: 1rem;
    }

    .available-everywhere {
      color: rgba(255, 255, 255, 0.64);
      font-family: "Lato";
      font-size: 16px;
      letter-spacing: 0.25px;
      white-space: nowrap;
      font-weight: bold;

      @media (max-width: 960px) {
        font-size: 13px;
        letter-spacing: 0.1625px;
      }
    }

    .header-test {
      // background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 20%);
      z-index: 10000;
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 20%;
      height: 55px;
      background: -moz-linear-gradient(top, rgba(32, 0, 69, 0) 0%, rgba(32, 0, 69, 1) 100%); /* FF3.6+ */
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, rgba(32, 0, 69, 0)),
        color-stop(100%, rgba(32, 0, 69, 1))
      ); /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(
        left,
        rgba(32, 0, 69, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      ); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(left, rgba(32, 0, 69, 0) 0%, rgba(32, 0, 69, 1) 100%); /* Opera 11.10+ */
      background: -ms-linear-gradient(left, rgba(32, 0, 69, 0) 0%, rgba(32, 0, 69, 1) 100%); /* IE10+ */
      background: linear-gradient(to left, rgba(32, 0, 69, 0) 0%, rgba(32, 0, 69, 1) 100%); /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#200045', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
    }

    .header-ticker {
      // background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 20%);
      // background-image: -webkit-gradient(
      //   linear,
      //   left top,
      //   left bottom,
      //   from(rgba(50, 50, 50, 0.8)),
      //   to(rgba(80, 80, 80, 0.2)),
      //   color-stop(0.5, #333333)
      // );
      // background-image: -webkit-linear-gradient(left, red 0%, rgba(255, 255, 255, 0) 20%);
      position: relative;
      width: 100%;
      height: 55px;
      display: flex;
      margin-left: 2rem;
      margin-right: 2rem;

      @media (max-width: 960px) {
        height: 59px;
      }
    }

    .header-app-container {
      padding: 0.5rem;
      height: 100%;
      flex-direction: row;
      display: flex;
      align-items: center;

      .header-app-button {
        min-width: 166px;
        max-width: 166px;
        height: 37px;
        display: flex;
        flex-direction: row;
        background-color: #7328cf;
        width: 100%;
        height: 100%;
        justify-content: center;
        border-radius: 18.5px;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        cursor: pointer;

        &.first {
          margin-right: 20px;
        }

        @media (max-width: 580px) {
          min-width: 90px;
        }

        @media (max-width: 960px) {
          margin-right: 7px;
          min-width: 87px;
          max-width: 87px;
          height: 28px;
        }
      }
    }
  }

  .header-mobile-button-container {
    min-width: 97px;
    height: 28px;
    max-width: 97px;
  }
}
