.appButton {
    background:#1CE6B4;
    padding:6px 16px;
    transition:all 200ms;
    width:100%;
    border-radius: 20px 50%;
    text-align:center;
    font-family:Lato;
    font-weight:400;
    font-size:1rem;
    color:#034F66;
    letter-spacing:-0.23px;
}
.clickable {
    cursor:pointer;
}
.clickable:active {
    opacity: .8;
}
