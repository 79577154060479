.root {
    width:275px;
    background:#222223;
    border-radius:20px;
    font-family:Lato;
    font-weight:700;
    font-size:1.125rem;
    color:#FFFFFF;
    letter-spacing:-0.23px;
    cursor:pointer;
    padding-top:8px;
    padding-bottom:8px;
    padding-left:33px;
    height:40px;
}
.root:hover {
    opacity: .75;
}
.icon {
    width:20px;
    height:100%;
    object-fit:contain;
:undefined;
}
