@import "../../App.scss";
$headline-size: 14px;
$station-number-size: 14px;

.guide-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 30px;

  @media (max-width: 480px) {
    padding-left: 7px;
    padding-right: 8px;
  }
}

.headline-text {
  font-family: "Lato";
  font-weight: 900; /* Regular */
  font-size: $headline-size;
  color: $color-headline;
  letter-spacing: 0.4px;
  line-height: 24px;
  //   max-height: 24px;
  height: 30px;
  text-align: left;
  cursor: default;

  display: flex;
  align-items: center;
}

.sticky2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 100%;
  margin-top: 10vh;
  overflow-x: scroll;
  width: 64% !important;
  margin-right: 20rem;
  margin-left: calc(254px + 14%);
  background-color: rgba(255, 255, 255, 1);
  color: rgba(255, 255, 255, 1);
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 100%;
  margin-top: 10vh;
  overflow-x: scroll;
  width: 120px;
  margin-right: 20rem;
  margin-left: calc(254px + 7%);
  background-color: rgba(255, 255, 255, 0.5);
  text-align: left;
}

.timeline-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 85px;

  @media (max-width: 960px) {
    height: 80px;
  }
  // position: relative;

  .time {
    padding-left: 10px;
  }
}

.fixed-cell {
  width: 156px;

  @media (max-width: 960px) {
    width: 115px;
  }
}

.separator {
  border-bottom: 2px solid $color-seperator;
}

.logo-container {
  display: flex;

  img {
    object-fit: contain;
  }
}

.station-number {
  font-family: "Lato";
  font-weight: bold; /* Regular */
  font-size: $station-number-size;
  color: $color-headline;
  letter-spacing: 0.4px;
  line-height: calc(2px + #{$station-number-size});
  max-height: calc(2 * (2px + #{$station-number-size}));
  text-align: right;
  // width: 30px;
  cursor: default;

  @media (max-width: 960px) {
    font-size: 10px;
    line-height: 12px;
    max-height: 12px;
    padding-right: 3px;
    // width: 18px;
  }
}

.station-heart {
  color: $color-primary-black;
  text-align: left;
  width: 100%;
  cursor: default;
  display: flex;
  align-items: flex-start;
}

.schedule-container {
  height: 85px;
  padding: 5px;
  padding-right: 13px;
  cursor: pointer;
  justify-content: space-between;

  @media (max-width: 960px) {
    height: 80px;
    padding-right: 2px;
  }
}

.genre-text {
  font-size: 32px;
  font-family: "Lato";
  font-weight: bold;
  color: $color-headline;

  @media (max-width: 960px) {
    font-size: 28px;
  }
}

.genre-text-container {
  // width: calc(100vw - 8.33% - 8.33%);

  // @media (min-width: 600px) {
  //   width: calc(100vw - 16.6667%) !important;
  // }

  @media (max-width: 599px) {
    width: 100vw !important;
  }
}

.program {
  display: flex;
  flex-direction: row;
  padding-top: 4px;
  padding-bottom: 3px;
  height: 100%;
  position: absolute;
}

.left-side {
  margin-top: 2rem;
}

.program-font {
  font-family: "Lato";
  font-weight: normal; /* Regular */
  font-size: $station-number-size;
  color: $color-headline;
  letter-spacing: 0.4px;
  line-height: calc(4px + #{$station-number-size});
  max-height: calc(2 * (2px + #{$station-number-size}));
  text-align: left;
  cursor: default;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &.station-bold {
    font-weight: 900;
  }
}

.program-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
}

.fixed-program {
  position: sticky;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.left1 {
  left: 1px;
}

.left11 {
  left: 11px;
}
