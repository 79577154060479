
.drawerTitle {
    margin-left:8px;
    font-family:Lato;
    font-weight:900;
    font-size:1.5625rem;
    color:#fff;
    letter-spacing:-0.32px;
    flex:1;
}

