.imageStyle {
    min-width:140px;
    max-width:140px;
    min-height:140px;
    max-height:140px;
    object-fit: contain;
}
@media (max-width: 320px) {
    .imageStyle {
        width: 64px;
        height: 64px;
    }
}
@media (max-width: 415px) {
    .imageStyle {
        width: 90px;
        height: 90px;
    }
}
.box{
    border-radius:14px;
    box-shadow:0px 0px 6px rgba(0, 0,0,0.25);
    width:261px;
    height:261px;
    padding: 15px 14px 6px;
    overflow:hidden;
    position:relative;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    margin:auto;
    cursor:pointer;
    transition:all 250ms;
}
.box:active {
    background-color: #A7A7AB5A;
}
.textBox {
    height:100%;
    overflow:hidden;
    text-overflow:ellipsis;
    align-self:stretch;
    text-align:start;
    vertical-align:bottom;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:flex-start;
}
.textNumber {
    font-family:Lato;
    font-weight:300;
    color:#222223;
    letter-spacing:-0.12px;
    font-size:13px;
    line-height:15px;
    height:18px;
    max-height:18px;
    overflow:hidden;
    text-overflow:ellipsis;
    width:100%;
    display:flex;
    min-height:18px;
}
