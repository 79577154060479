.clickable {
    cursor: pointer;
}
.clickable:hover {
    opacity: .9;
}
.container {
    position:relative;
    width:100%;
    overflow:hidden;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
}
.centerBox {
    height:100px;
    display:flex;
    align-items:center;
    justify-content:center;
    padding:1vmax;
}
.appsBox {
    width:100%;
    height:100%;
    object-fit:contain;
    display:flex;
    align-items:center;
    justify-content:center;
}
.backgroundImage {
    object-fit:cover;
    object-position:center;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}
.logoImage {
    object-fit:contain;
    object-position:center;
    position:relative;
    width:100%;
    height:100%;
    max-width:96px;
}
.appsImage {
    object-fit:contain;
    object-position:center;
    position:relative;
    width:100%;
    height:100%;
}
.itemsContainer {
    width:100%;
    position:relative;
    height:100%;
}
@media (max-width: 415px) {
    .itemsContainer {
        width: 100%;
        padding: 8px;
    }
    .chevronContainer {
        width: 0;
        display: none;
    }
}
.chevronContainer {
    display:flex;
    align-items:center;
    justify-content:center;
    flex:0;
    width:32px;
    height:48px;
    cursor:pointer;
    margin:auto;
}
.chevronContainer:hover {
    opacity: .75;
}
.chevronContainer:active {
    opacity: 1;
}
.chevron {
    width:24px;
    height:24px;
    object-fit:contain;
}
.root {
    display:flex;
    flex-wrap:wrap;
    justify-content:flex-start;
    overflow:hidden;
}
.title {
    color:white;
    font-family:Lato;
    font-weight:bold;
}
.titleBar{
    background:black;
    opacity:.8;
}
.titleWrap{
    display:flex;
    justify-content:flex-start;
}
.gridListTitle {
    width:100%;
    height:100%;
    object-fit:cover;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-right:1px;
    margin-left:1px;
}

