.videoOverlay{
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #06102C;
    opacity: .8;
    z-index: 100;
    height: 100%;
    left: 50%;
    transform: translate(-50%);
}
.overlay {
    width: 100%;
    margin: auto;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
div.headLineBigText {
    color: white;
    font-size: 4rem;
    text-align: center;
    line-height: 4rem;
}

