.appbar {
  top: unset !important;
  bottom: 0px;
  width: calc(100vw - 222px) !important;
  right: 0px !important;
  transition: all 250ms !important;
  height: 107px;
  overflow: hidden;
  background: linear-gradient(270deg, #ff3f90 0%, #200045 100%);
  box-shadow: 0px -4px 24px rgba(0, 0, 0, 0.25) !important;
  color: white !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  flex-direction: row !important;
  min-width: 0px;

  @media (max-width: 960px) {
    padding-left: 0px;
    width: 100% !important;
    height: 73px !important;
  }

  .main-container {
    flex-direction: row;
    display: flex;
    flex: 1;
    justify-content: center;
  }

  .station-logo-left {
    height: 100%;
    cursor: pointer;

    @media (max-width: 960px) {
      flex: 0 0 73px;
    }
  }

  .music-dataview-container {
    cursor: pointer;
    flex-direction: column;
    display: flex;
    flex: 1;
    overflow: hidden;
  }

  .heart-lyrics-coontainer {
    flex-direction: row;
    display: flex;
    align-items: center;
    flex: 1;

    &.mobile {
      margin-left: 0px !important;
    }

    .station-heart {
      .image-heart {
        display: flex;
        align-items: top;
        color: #ff0000;
        cursor: pointer;
        width: 12px;
        height: 11px;

        @media (min-width: 600px) {
          width: 29px;
          height: 26px;
        }
      }
    }

    .lyrics-container {
      .lyrics {
        font-family: "Lato";
        font-weight: normal;
        font-size: 22px;
        letter-spacing: 0.628572px;
        color: white;
        cursor: pointer;

        @media (max-width: 960px) {
          font-size: 14px;
          line-height: 15px;
          letter-spacing: 0.4px;
        }
      }
    }
  }

  .clean-dirty-img {
    height: 11px;
    width: 11px;

    @media (min-width: 600px) {
      width: 26px;
      height: 26px;
    }
  }

  .play-next-prev-container {
    flex-direction: row;
    display: flex;
    flex: 1;

    .prev-next {
      padding: 12px;
    }
    .prev {
      margin-right: 6px;

      @media (min-width: 600px) {
        margin-right: 12px;
      }

      @media (min-width: 961px) {
        margin-right: 20px;
      }
    }
    .next {
      margin-left: 6px;

      @media (min-width: 600px) {
        margin-left: 12px;
      }

      @media (min-width: 961px) {
        margin-left: 20px;
      }
    }

    .play-pause {
      padding: 0px !important;
    }

    .icon-style {
      width: 11.5px !important;
      height: 8.5px !important;
      object-fit: contain !important;
      color: white !important;

      @media (min-width: 625px) {
        width: 19px !important;
        height: 15px !important;
      }
    }
  }
  .volume-slider-container {
    display: flex;
    flex-direction: row;
    width: 151px;
    align-items: center;

    .icon-style-small {
      width: 21px;
      height: 15px;
      object-fit: contain;
      color: white;
    }
  }

  .station-logo {
    width: 25px;
    height: 25px;
    object-fit: contain;
    cursor: pointer;

    @media (min-width: 600px) {
      width: 46px;
      height: 46px;
    }
  }

  .explicit-clean-container {
    display: flex;
    align-items: center;
    flex: 1;

    &.mobile {
      margin-left: 0px !important;
    }

    .explicit-clean {
      font-size: 17px;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: 0.074375px;
    }
  }

  .image-container {
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 960px) {
      padding: 12px;
    }

    @media (max-width: 550px) {
      padding-left: 6px;
      padding-right: 6px;
    }

    @media (max-width: 500px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.clean-dirty-img-container {
  display: flex;
}

.icon-style-play {
  width: 28px !important;
  height: 28px !important;
  object-fit: contain !important;
  color: white !important;

  @media (min-width: 600px) {
    width: 43px !important;
    height: 43px !important;
  }
}

.icon-style {
  width: 28px !important;
  height: 23px !important;
  object-fit: contain !important;
  color: white !important;

  @media (max-width: 480px) {
    width: 20px !important;
  }
}

.marquee-container {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
}

.spacing {
  min-width: 20px;

  @media (min-width: 600px) {
    min-width: 25px;
  }

  @media (min-width: 961px) {
    min-width: 35px;
  }
}

.spacing-logo {
  width: 20px;
  flex: 1;
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
  font-family: "Lato";
  font-weight: 900;
  font-size: 22px;
  color: white;
  letter-spacing: 0.628572px;
  line-height: 24px;
  overflow: hidden;

  &.artist {
    font-family: "Lato";
    font-weight: normal;
    font-size: 15px;
    color: white;
    letter-spacing: -0.17px;
    line-height: 17px;
    overflow: hidden;

    @media (max-width: 480px) {
      font-size: 10px;
      letter-spacing: 0.285714px;
    }
  }

  @media (max-width: 960px) {
    font-size: 15px;
    letter-spacing: 0.428571px;
  }

  @media (max-width: 480px) {
    font-size: 10px;
    letter-spacing: 0.285714px;
  }
}

// .marquee span:hover {
//   animation-play-state: paused;
// }

.marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 10s linear infinite;
  // -webkit-marquee: right medium 2 normal scroll;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.volume-slider {
  .MuiSlider-thumb {
    height: 0;
    width: 0;
  }
  .MuiSlider-thumb:hover {
    box-shadow: none;
  }
  .MuiSlider-track {
    height: 2px;
    border-radius: 2px;
  }
  .MuiSlider-rail {
    height: 2px;
    border-radius: 2px;
  }
}
