.storebutton {
    height:40px;
    object-fit:contain;
    margin-top:8px;
    margin-right:16px;
    flex-shrink:0;
}
.clickable {
    cursor:pointer;
}
.clickable:active {
    opacity: .9;
}
.iconcontainer{
    display:flex;
    flex-direction:row;
    background-color:#7328CF;
    width:100%;
    height:100%;
    justify-content:center;
    border-radius: 18.5px;
    padding-top:.4rem;
    padding-bottom:.4rem;
}
.imgStyle {
    object-fit: contain;
}
.textContainer {
    color:white;
    display:flex;
    align-items:center;
    margin-left:6px;
    font-size:.825rem;
    font-family:Lato;
}
